(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .controller('RegistrationController', RegistrationController);

    RegistrationController.$inject = ['$scope', '$timeout', '$state', 'Registration', 'RegistrationSearch', 'ParseLinks', 'DateUtils',
        'AlertService', 'pagingParams', 'paginationConstants', 'Principal', '$uibModal', 'Tag', 'HairColor', 'EyeColor', 'Glass',
        'Language', 'LanguageLevel', 'Race', 'MusicInstrument', 'Sport', 'Dance'];

    function RegistrationController ($scope, $timeout, $state, Registration, RegistrationSearch, ParseLinks, DateUtils,
         AlertService, pagingParams, paginationConstants, Principal, $uibModal, Tag, HairColor, EyeColor, Glass,
         Language, LanguageLevel, Race, MusicInstrument, Sport, Dance) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = $state.current.name === 'registration-search' ? 60 : 100;
        vm.clear = clear;
        vm.isAdmin = true;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.processSearchObject = processSearchObject;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.calculateAge = DateUtils.calculateAge;
        vm.printTags = printTags;
        vm.openSearchHelpDialog = openSearchHelpDialog;
        vm.searchObject = initSearchObject();
        Tag.queryNoPagination(null, function (result) {
            vm.tags = result;
            if (vm.currentSearch) {
                vm.searchObject.tags = checkMultiSelectSearchCondition(vm.currentSearch, 'tags.id', result);
            }
        });
        HairColor.queryNoPagination(null, function (result) {
            vm.haircolors = result;
            if (vm.currentSearch) {
                vm.searchObject.hairColors = checkMultiSelectSearchCondition(vm.currentSearch, 'hairColor.id', result);
            }
        });
        EyeColor.queryNoPagination(null, function (result) {
            vm.eyecolors = result;
            if (vm.currentSearch) {
                vm.searchObject.eyeColors = checkMultiSelectSearchCondition(vm.currentSearch, 'eyeColor.id', result);
            }
        });
        Glass.queryNoPagination(null, function (result) {
            vm.glasses = result;
            if (vm.currentSearch) {
                vm.searchObject.glasses = checkMultiSelectSearchCondition(vm.currentSearch, 'glasses.id', result);
            }
        });
        Language.queryNoPagination(null, function (result) {
            vm.languages = result;
            if (vm.currentSearch) {
                vm.searchObject.languages = checkLanguageSearchCondition(vm.currentSearch, result);
            }
        });
        LanguageLevel.queryNoPagination(null, function (result) {
            vm.languagelevels = result;
            if (vm.currentSearch) {
                vm.searchObject.languageLevels = checkLanguageLevelSearchCondition(vm.currentSearch, result);
            }
        });
        Race.queryNoPagination(null, function (result) {
            vm.races = result;
            if (vm.currentSearch) {
                vm.searchObject.races = checkMultiSelectSearchCondition(vm.currentSearch, 'race.id', result);
            }
        });
        MusicInstrument.queryNoPagination(null, function (result) {
            vm.musicinstruments = result;
            if (vm.currentSearch) {
                vm.searchObject.musicInstruments = checkMultiSelectSearchCondition(vm.currentSearch, 'musicInstruments.id', result);
            }
        });
        Sport.queryNoPagination(null, function (result) {
            vm.sports = result;
            if (vm.currentSearch) {
                vm.searchObject.sports = checkMultiSelectSearchCondition(vm.currentSearch, 'sports.id', result);
            }
        });
        Dance.queryNoPagination(null, function (result) {
            vm.dances = result;
            if (vm.currentSearch) {
                vm.searchObject.dances = checkMultiSelectSearchCondition(vm.currentSearch, 'dances.id', result);
            }
        });


        loadAll();

        $timeout(function (){
            angular.element('#name').focus();
        });

        function loadAll () {
            if (pagingParams.search) {
                loadSearchObject(pagingParams.search);
                RegistrationSearch.query({
                    query: pagingParams.search + ' AND lobby: false',
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                if ($state.current.name != 'registration-search') {
                    Registration.query({
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort()
                    }, onSuccess, onError);
                }
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate == 'lastName') {
                    result.push('firstName' + ',' + (vm.reverse ? 'asc' : 'desc'));
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.registrations = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function initSearchObject() {
            return {
                ageFrom: null,
                ageTo: null,
                gender: null,
                name: null,
                tags: null,
                eyeColors: null,
                hairColors: null,
                glasses: null,
                languages: null,
                languageLevels: null,
                race: null,
                heightFrom: null,
                heightTo: null,
                weightFrom: null,
                weightTo: null,
                clothesSizeFrom: null,
                clothesSizeTo: null,
                shoeSizeFrom: null,
                shoeSizeTo: null,
                chestFrom: null,
                chestTo: null,
                waistSizeFrom: null,
                waistSizeTo: null,
                hipSizeFrom: null,
                hipSizeTo: null,
                neckSizeFrom: null,
                neckSizeTo: null,
                headSizeFrom: null,
                headSizeTo: null,
                phone: null,
                email: null,
                address: null,
                filmRole: null,
                advertisement: null,
                crowd: null,
                swimSuitRole: null,
                halfNudeRole: null,
                nudeRole: null,
                tattoo: null,
                piercing: null,
                scar: null,
                birthCity: null,
                taxNumber: null,
                niNumber: null,
                birthName: null,
                nationality: null,
                motherName: null,
                agent: null,
                twins: null,
                comment: null
            };
        }

        function loadSearchObject(search) {
            vm.searchObject.ageFrom = checkAgeFromSearchCondition(search);
            vm.searchObject.ageTo = checkAgeToSearchCondition(search);
            vm.searchObject.gender = checkSimpleSearchCondition(search, 'gender');
            vm.searchObject.name = checkNameSearchCondition(search);
            vm.searchObject.eyeColors = null;
            vm.searchObject.hairColors = null;
            vm.searchObject.glasses = null;
            vm.searchObject.languages = null;
            vm.searchObject.languageLevels = null;
            vm.searchObject.races = null;
            vm.searchObject.heightFrom = checkFromSearchCondition(search, 'height');
            vm.searchObject.heightTo = checkToSearchCondition(search, 'height');
            vm.searchObject.weightFrom = checkFromSearchCondition(search, 'weight');
            vm.searchObject.weightTo = checkToSearchCondition(search, 'weight');
            vm.searchObject.clothesSizeFrom = checkFromSearchCondition(search, 'clothesSize');
            vm.searchObject.clothesSizeTo = checkToSearchCondition(search, 'clothesSize');
            vm.searchObject.shoeSizeFrom = checkFromSearchCondition(search, 'shoeSize');
            vm.searchObject.shoeSizeTo = checkToSearchCondition(search, 'shoeSize');
            vm.searchObject.chestSizeFrom = checkFromSearchCondition(search, 'chestSize');
            vm.searchObject.chestSizeTo = checkToSearchCondition(search, 'chestSize');
            vm.searchObject.waistSizeFrom = checkFromSearchCondition(search, 'waistSize');
            vm.searchObject.waistSizeTo = checkToSearchCondition(search, 'waistSize');
            vm.searchObject.hipSizeFrom = checkFromSearchCondition(search, 'hipSize');
            vm.searchObject.hipSizeTo = checkToSearchCondition(search, 'hipSize');
            vm.searchObject.neckSizeFrom = checkFromSearchCondition(search, 'neckSize');
            vm.searchObject.neckSizeTo = checkToSearchCondition(search, 'neckSize');
            vm.searchObject.headSizeFrom = checkFromSearchCondition(search, 'headSize');
            vm.searchObject.headSizeTo = checkToSearchCondition(search, 'headSize');
            vm.searchObject.phone = checkSimpleSearchCondition(search, 'phone');
            vm.searchObject.email = checkSimpleSearchCondition(search, 'email');
            vm.searchObject.address = checkAddressSearchCondition(search);
            vm.searchObject.filmRole = checkSimpleSearchCondition(search, 'filmRole');
            vm.searchObject.advertisement = checkSimpleSearchCondition(search, 'advertisement');
            vm.searchObject.crowd = checkSimpleSearchCondition(search, 'crowd');
            vm.searchObject.swimSuitRole = checkSimpleSearchCondition(search, 'swimSuitRole');
            vm.searchObject.halfNudeRole = checkSimpleSearchCondition(search, 'halfNudeRole');
            vm.searchObject.nudeRole = checkSimpleSearchCondition(search, 'nudeRole');
            vm.searchObject.tattoo = checkSimpleSearchCondition(search, 'tattoo');
            vm.searchObject.piercing = checkSimpleSearchCondition(search, 'piercing');
            vm.searchObject.scar = checkSimpleSearchCondition(search, 'scar');
            vm.searchObject.birthCity = checkSimpleSearchCondition(search, 'birthCity');
            vm.searchObject.taxNumber = checkSimpleSearchCondition(search, 'taxNumber');
            vm.searchObject.niNumber = checkSimpleSearchCondition(search, 'niNumber');
            vm.searchObject.birthName = checkSimpleSearchCondition(search, 'birthName');
            vm.searchObject.nationality = checkSimpleSearchCondition(search, 'nationality');
            vm.searchObject.motherName = checkSimpleSearchCondition(search, 'motherName');
            vm.searchObject.agent = checkSimpleSearchCondition(search, 'agent');
            vm.searchObject.twins = checkSimpleSearchCondition(search, 'twins');
            vm.searchObject.comment = checkCommentSearchCondition(search);
        }

        function checkNameSearchCondition(search) {
            var regexpString = '\\(\\s*lastName\\s*:.+?OR';
            var regexp = new RegExp(regexpString);
            var match = regexp.exec(search);
            if (match == null) {
                regexp = new RegExp(regexpString + '$');
                match = regexp.exec(search);
            }

            if (match != null) {
                var value = "";
                for (var i = 0; i < match.length; i++) {
                    value = value + match[i];
                }

                value = value.substring(1, value.length);
                value = value.replace(new RegExp('lastName\\s*:\\s*'), '');
                value = value.replace(new RegExp('\\s*OR'), '');
                console.log('value: ' + value);
                return value.trim();
            }
            else {
                return null;
            }
        }

        function checkAddressSearchCondition(search) {
            var regexpString = '\\(\\s*permanentAddress\\s*:.+?OR';
            var regexp = new RegExp(regexpString);
            var match = regexp.exec(search);

            if (match != null) {
                var value = "";
                for (var i = 0; i < match.length; i++) {
                    value = value + match[i];
                }

                value = value.substring(1, value.length);
                value = value.replace(new RegExp('permanentAddress\\s*:\\s*'), '');
                value = value.replace(new RegExp('\\s*OR'), '');
                console.log('value: ' + value);
                return value.trim();
            }
            else {
                return null;
            }
        }

        function checkSimpleSearchCondition(search, field) {
            var regexpString = '\\b' + field + '\\b\\s*:.+?';
            var regexp = new RegExp(regexpString + '\\s*AND');
            var match = regexp.exec(search);
            if (match == null) {
                regexp = new RegExp(regexpString + '$');
                match = regexp.exec(search);
            }

            if (match != null) {
                var value = "";
                for (var i = 0; i < match.length; i++) {
                    value = value + match[i];
                }

                value = value.replace(new RegExp(field + '\\s*:\\s*'), '');
                value = value.replace(new RegExp('\\s*AND$'), '');
                console.log('value: ' + value);
                return value.trim();
            }
            else {
                return null;
            }
        }

        function checkFromSearchCondition(search, field) {
            var regexpString = '\\b' + field + '\\b:\\s*\\[.+?\\s*TO\\s\\*\\]';
            var regexp = new RegExp(regexpString);
            var match = regexp.exec(search);

            if (match != null) {
                var value = "";
                for (var i = 0; i < match.length; i++) {
                    value = value + match[i];
                }

                value = value.replace(new RegExp(field + ':\\s*\\[\\s*'), '');
                value = value.replace(new RegExp('\\s*TO\\s*\\*\\]'), '');
                console.log('value: ' + value);
                return parseInt(value.trim());
            }
            else {
                return null;
            }
        }

        function checkToSearchCondition(search, field) {
            var regexpString = '\\b' + field + '\\b:\\s*\\[\\*\\s*TO.+?\\]';
            var regexp = new RegExp(regexpString);
            var match = regexp.exec(search);

            if (match != null) {
                var value = "";
                for (var i = 0; i < match.length; i++) {
                    value = value + match[i];
                }

                value = value.replace(new RegExp(field + ':\\s*\\[\\*\\s*TO'), '');
                value = value.replace(new RegExp('\\s*\\]'), '');
                console.log('value: ' + value);
                return parseInt(value.trim());
            }
            else {
                return null;
            }
        }

        function checkAgeFromSearchCondition(search) {
            var regexpString = 'birthDate:\\s*\\[\\*\\s*TO.+?\\]';
            var regexp = new RegExp(regexpString);
            var match = regexp.exec(search);

            if (match != null) {
                var value = "";
                for (var i = 0; i < match.length; i++) {
                    value = value + match[i];
                }

                value = value.replace(new RegExp('birthDate:\\s*\\[\\*\\s*TO'), '');
                value = value.replace(new RegExp('\\s*\\]'), '');
                console.log('value: ' + value);
                return DateUtils.calculateAge(value);
            }
            else {
                return null;
            }
        }

        function checkAgeToSearchCondition(search) {
            var regexpString = 'birthDate:\\s\\[.+?\\sTO\\s\\*\\]';
            var regexp = new RegExp(regexpString);
            var match = regexp.exec(search);

            if (match != null) {
                var value = "";
                for (var i = 0; i < match.length; i++) {
                    value = value + match[i];
                }

                console.log('value before: ' + value);
                value = value.replace(new RegExp('birthDate:\\s\\[\\s*'), '');
                value = value.replace(new RegExp('\\s*TO\\s*\\*\\]'), '');
                console.log('value: ' + value);
                return DateUtils.calculateAge(value);
            }
            else {
                return null;
            }
        }

        function checkMultiSelectSearchCondition(search, field, objectArray) {
            var values = [];
            var regexpString = '\\(\\s*' + field + '\\s*:.+?\\)';
            var regexp = new RegExp(regexpString + '\\s*AND');
            var match = regexp.exec(search);

            if (match == null) {
                regexp = new RegExp(regexpString + '\\s*$');
                match = regexp.exec(search);
            }

            if (match != null) {
                var value = "";
                for (var i = 0; i < match.length; i++) {
                    value = value + match[i];
                }
                console.log('value:' + value);
                value = value.substring(1, value.lastIndexOf(')'));
                values = value.split(new RegExp('\\s*OR\\s*'));
                for (var x = 0; x < values.length;x++) {
                    values[x] = values[x].replace(new RegExp(field + '\\s*:\\s*'), '');
                    values[x] = values[x].trim();
                }
            }

            if (!values.length) {
                return null;
            }

            var objectValues = [];
            for (var z = 0; z < values.length; z++) {
                for (var y = 0; y < objectArray.length; y++) {
                    if (objectArray[y].id == values[z]) {
                        objectValues.push(objectArray[y]);
                        break;
                    }
                }
            }
            return objectValues;
        }

        function checkLanguageSearchCondition(search, languages) {
            var values = [];
            var regexp = /\(language1.id\s*:.+?\)/g;
            var match;

            while ((match = regexp.exec(search)) !== null) {
                if (match[0]) {
                    values.push(match[0]);
                }
            }

            if (values.length) {
                for (var x = 0; x < values.length;x++) {
                    console.log('value:' + values[x]);
                    values[x] = values[x].substring(1, values[x].lastIndexOf(')'));
                    values[x] = values[x].replace(new RegExp('language1.id:\\s*'), '');
                    values[x] = values[x].trim();
                }
            }

            if (!values.length) {
                return null;
            }

            var objectValues = [];
            for (var z = 0; z < values.length; z++) {
                for (var y = 0; y < languages.length; y++) {
                    if (languages[y].id == values[z]) {
                        objectValues.push(languages[y]);
                        break;
                    }
                }
            }
            return objectValues;
        }

        function checkLanguageLevelSearchCondition(search, languageLevels) {
            var values = [];
            var regexpString = '\\(languageLevel1.id\\s*:.+?\\)';
            var regexp = new RegExp(regexpString);
            var match = regexp.exec(search);

            if (match != null) {
                var value = "";
                for (var i = 0; i < match.length; i++) {
                    value = value + match[i];
                }
                console.log('value:' + value);
                value = value.substring(1, value.lastIndexOf(')'));
                values = value.split(new RegExp('\\s*OR\\s*'));
                for (var x = 0; x < values.length;x++) {
                    values[x] = values[x].replace(new RegExp('languageLevel1.id:\\s*'), '');
                    values[x] = values[x].trim();
                }
            }

            if (!values.length) {
                return null;
            }

            var objectValues = [];
            for (var z = 0; z < values.length; z++) {
                for (var y = 0; y < languageLevels.length; y++) {
                    if (languageLevels[y].id == values[z]) {
                        objectValues.push(languageLevels[y]);
                        break;
                    }
                }
            }
            return objectValues;
        }

        function checkCommentSearchCondition(search) {
            var regexpString = 'comment\\s*:.+?\\s*OR';
            var regexp = new RegExp(regexpString);
            var match = regexp.exec(search);
            if (match == null) {
                regexp = new RegExp(regexpString + '$');
                match = regexp.exec(search);
            }

            if (match != null) {
                var value = "";
                for (var i = 0; i < match.length; i++) {
                    value = value + match[i];
                }

                value = value.replace(new RegExp('comment\\s*:\\s*'), '');
                value = value.replace(new RegExp('\\s*OR$'), '');
                console.log('value: ' + value);
                return value.trim();
            }
            else {
                return null;
            }
        }

        function processSearchObject() {
            var query = "";
            if (vm.searchObject.ageTo) {
                var d = new Date();
                var ageTo = d.getFullYear() - vm.searchObject.ageTo;
                query += 'birthDate: [' + ageTo + '-01-01 TO *]';
            }

            if (vm.searchObject.ageFrom) {
                query = delimiter(query);
                var d = new Date();
                var ageFrom = d.getFullYear() - vm.searchObject.ageFrom;
                query += 'birthDate: [* TO ' + ageFrom + '-01-01]';
            }

            if (vm.searchObject.gender) {
                query = delimiter(query);
                query += 'gender: ' + vm.searchObject.gender;
            }

            if (vm.searchObject.tags && vm.searchObject.tags.length) {
                query = delimiter(query);
                query += '(';
                for (var i = 0; i < vm.searchObject.tags.length; i++) {
                    query += i > 0 ? ' OR ' : '';
                    query += 'tags.id: ' + vm.searchObject.tags[i].id;
                }
                query += ')';
            }

            if (vm.searchObject.hairColors && vm.searchObject.hairColors.length) {
                query = delimiter(query);
                query += '(';
                for (var i = 0; i < vm.searchObject.hairColors.length; i++) {
                    query += i > 0 ? ' OR ' : '';
                    query += 'hairColor.id: ' + vm.searchObject.hairColors[i].id;
                }
                query += ')';
            }

            if (vm.searchObject.eyeColors && vm.searchObject.eyeColors.length) {
                query = delimiter(query);
                query += '(';
                for (var i = 0; i < vm.searchObject.eyeColors.length; i++) {
                    query += i > 0 ? ' OR ' : '';
                    query += 'eyeColor.id: ' + vm.searchObject.eyeColors[i].id;
                }
                query += ')';
            }

            if (vm.searchObject.heightFrom) {
                query = delimiter(query);
                query += 'height: [' + vm.searchObject.heightFrom + ' TO *]';
            }

            if (vm.searchObject.heightTo) {
                query = delimiter(query);
                query += 'height: [* TO ' + vm.searchObject.heightTo + ']';
            }

            if (vm.searchObject.weightFrom) {
                query = delimiter(query);
                query += 'weight: [' + vm.searchObject.weightFrom + ' TO *]';
            }

            if (vm.searchObject.weightTo) {
                query = delimiter(query);
                query += 'weight: [* TO ' + vm.searchObject.weightTo + ']';
            }

            if (vm.searchObject.clothesSizeFrom) {
                query = delimiter(query);
                query += 'clothesSize: [' + vm.searchObject.clothesSizeFrom + ' TO *]';
            }

            if (vm.searchObject.clothesSizeTo) {
                query = delimiter(query);
                query += 'clothesSize: [* TO ' + vm.searchObject.clothesSizeTo + ']';
            }

            if (vm.searchObject.shoeSizeFrom) {
                query = delimiter(query);
                query += 'shoeSize: [' + vm.searchObject.shoeSizeFrom + ' TO *]';
            }

            if (vm.searchObject.shoeSizeTo) {
                query = delimiter(query);
                query += 'shoeSize: [* TO ' + vm.searchObject.shoeSizeTo + ']';
            }

            if (vm.searchObject.chestSizeFrom) {
                query = delimiter(query);
                query += 'chestSize: [' + vm.searchObject.chestSizeFrom + ' TO *]';
            }

            if (vm.searchObject.chestSizeTo) {
                query = delimiter(query);
                query += 'chestSize: [* TO ' + vm.searchObject.chestSizeTo + ']';
            }

            if (vm.searchObject.waistSizeFrom) {
                query = delimiter(query);
                query += 'waistSize: [' + vm.searchObject.waistSizeFrom + ' TO *]';
            }

            if (vm.searchObject.waistSizeTo) {
                query = delimiter(query);
                query += 'waistSize: [* TO ' + vm.searchObject.waistSizeTo + ']';
            }

            if (vm.searchObject.hipSizeFrom) {
                query = delimiter(query);
                query += 'hipSize: [' + vm.searchObject.hipSizeFrom + ' TO *]';
            }

            if (vm.searchObject.hipSizeTo) {
                query = delimiter(query);
                query += 'hipSize: [* TO ' + vm.searchObject.hipSizeTo + ']';
            }

            if (vm.searchObject.neckSizeFrom) {
                query = delimiter(query);
                query += 'neckSize: [' + vm.searchObject.neckSizeFrom + ' TO *]';
            }

            if (vm.searchObject.neckSizeTo) {
                query = delimiter(query);
                query += 'neckSize: [* TO ' + vm.searchObject.neckSizeTo + ']';
            }

            if (vm.searchObject.headSizeFrom) {
                query = delimiter(query);
                query += 'headSize: [' + vm.searchObject.headSizeFrom + ' TO *]';
            }

            if (vm.searchObject.headSizeTo) {
                query = delimiter(query);
                query += 'headSize: [* TO ' + vm.searchObject.headSizeTo + ']';
            }

            if (vm.searchObject.glasses && vm.searchObject.glasses.length) {
                query = delimiter(query);
                query += '(';
                for (var i = 0; i < vm.searchObject.glasses.length; i++) {
                    query += i > 0 ? ' OR ' : '';
                    query += 'glasses.id: ' + vm.searchObject.glasses[i].id;
                }
                query += ')';
            }

            if (vm.searchObject.languages && vm.searchObject.languages.length
                && vm.searchObject.languageLevels && vm.searchObject.languageLevels.length) {
                query = delimiter(query);
                query += '(';
                for (var i = 0; i < vm.searchObject.languages.length; i++) {
                    query += i > 0 ? ' OR ' : '';
                    for (var y = 1; y <= 5; y++) {
                        query += y > 1 ? ' OR ' : '';
                        query += '(';
                        query += '(language' + y + '.id: ' + vm.searchObject.languages[i].id + ')';
                        query += ' AND ';

                        query += ' (';
                        for (var z = 0; z < vm.searchObject.languageLevels.length; z++) {
                            query += z > 0 ? ' OR ' : '';
                            query += 'languageLevel' + y + '.id: ' + vm.searchObject.languageLevels[z].id;
                        }
                        query += ')';
                        query += ')';
                    }
                }
                query += ')';
            } else {
                if (vm.searchObject.languages && vm.searchObject.languages.length) {
                    query = delimiter(query);
                    query += '(';
                    for (var y = 1; y <= 5; y++) {
                        query += y > 1 ? ' OR ' : '';
                        query += '(';
                        for (var i = 0; i < vm.searchObject.languages.length; i++) {
                            query += i > 0 ? ' OR ' : '';
                            query += '(language' + y + '.id: ' + vm.searchObject.languages[i].id + ')';
                        }
                        query += ')';
                    }

                    query += ' OR (';
                    for (var i = 0; i < vm.searchObject.languages.length; i++) {
                        query += i > 0 ? ' OR ' : '';
                        query += 'motherLanguage.id: ' + vm.searchObject.languages[i].id;
                    }
                    query += ')';

                    query += ')';
                }

                if (vm.searchObject.languageLevels && vm.searchObject.languageLevels.length) {
                    query = delimiter(query);
                    query += '(';
                    for (var y = 1; y <= 5; y++) {
                        query += y > 1 ? ' OR ' : '';
                        query += '(';
                        for (var i = 0; i < vm.searchObject.languageLevels.length; i++) {
                            query += i > 0 ? ' OR ' : '';
                            query += 'languageLevel' + y + '.id: ' + vm.searchObject.languageLevels[i].id;
                        }
                        query += ')';
                    }
                    query += ')';
                }
            }

            if (vm.searchObject.races && vm.searchObject.races.length) {
                query = delimiter(query);
                query += '(';
                for (var i = 0; i < vm.searchObject.races.length; i++) {
                    query += i > 0 ? ' OR ' : '';
                    query += 'race.id: ' + vm.searchObject.races[i].id;
                }
                query += ')';
            }

            if (vm.searchObject.name) {
                query = delimiter(query);
                query += '(lastName: ' + vm.searchObject.name + ' OR ' + 'firstName: ' + vm.searchObject.name + ')';
            }

            if (vm.searchObject.phone) {
                query = delimiter(query);
                query += 'phone: ' + vm.searchObject.phone;
            }

            if (vm.searchObject.email) {
                query = delimiter(query);
                query += 'email: ' + vm.searchObject.email;
            }

            if (vm.searchObject.address) {
                query = delimiter(query);
                query += '(permanentAddress: ' + vm.searchObject.address + ' OR ' + 'addressCities: ' + vm.searchObject.address + ')';
            }

            if (vm.searchObject.musicInstruments && vm.searchObject.musicInstruments.length) {
                query = delimiter(query);
                query += '(';
                for (var i = 0; i < vm.searchObject.musicInstruments.length; i++) {
                    query += i > 0 ? ' OR ' : '';
                    query += 'musicInstruments.id: ' + vm.searchObject.musicInstruments[i].id;
                }
                query += ')';
            }

            if (vm.searchObject.sports && vm.searchObject.sports.length) {
                query = delimiter(query);
                query += '(';
                for (var i = 0; i < vm.searchObject.sports.length; i++) {
                    query += i > 0 ? ' OR ' : '';
                    query += 'sports.id: ' + vm.searchObject.sports[i].id;
                }
                query += ')';
            }

            if (vm.searchObject.dances && vm.searchObject.dances.length) {
                query = delimiter(query);
                query += '(';
                for (var i = 0; i < vm.searchObject.dances.length; i++) {
                    query += i > 0 ? ' OR ' : '';
                    query += 'dances.id: ' + vm.searchObject.dances[i].id;
                }
                query += ')';
            }

            if (vm.searchObject.filmRole) {
                query = delimiter(query);
                query += 'filmRole: ' + vm.searchObject.filmRole;
            }

            if (vm.searchObject.advertisement) {
                query = delimiter(query);
                query += 'advertisement: ' + vm.searchObject.advertisement;
            }

            if (vm.searchObject.crowd) {
                query = delimiter(query);
                query += 'crowd: ' + vm.searchObject.crowd;
            }

            if (vm.searchObject.swimSuitRole) {
                query = delimiter(query);
                query += 'swimSuitRole: ' + vm.searchObject.swimSuitRole;
            }

            if (vm.searchObject.halfNudeRole) {
                query = delimiter(query);
                query += 'halfNudeRole: ' + vm.searchObject.halfNudeRole;
            }

            if (vm.searchObject.nudeRole) {
                query = delimiter(query);
                query += 'nudeRole: ' + vm.searchObject.nudeRole;
            }

            if (vm.searchObject.tattoo) {
                query = delimiter(query);
                query += 'tattoo: ' + vm.searchObject.tattoo;
            }

            if (vm.searchObject.piercing) {
                query = delimiter(query);
                query += 'piercing: ' + vm.searchObject.piercing;
            }

            if (vm.searchObject.scar) {
                query = delimiter(query);
                query += 'scar: ' + vm.searchObject.scar;
            }

            if (vm.searchObject.birthCity) {
                query = delimiter(query);
                query += 'birthCity: ' + vm.searchObject.birthCity;
            }

            if (vm.searchObject.taxNumber) {
                query = delimiter(query);
                query += 'taxNumber: ' + vm.searchObject.taxNumber;
            }

            if (vm.searchObject.niNumber) {
                query = delimiter(query);
                query += 'niNumber: ' + vm.searchObject.niNumber;
            }

            if (vm.searchObject.birthName) {
                query = delimiter(query);
                query += 'birthName: ' + vm.searchObject.birthName;
            }

            if (vm.searchObject.nationality) {
                query = delimiter(query);
                query += 'nationality: ' + vm.searchObject.nationality;
            }

            if (vm.searchObject.motherName) {
                query = delimiter(query);
                query += 'motherName: ' + vm.searchObject.motherName;
            }

            if (vm.searchObject.agent) {
                query = delimiter(query);
                query += 'agent: ' + vm.searchObject.agent;
            }

            if (vm.searchObject.twins) {
                query = delimiter(query);
                query += 'twins: ' + vm.searchObject.twins;
            }

            if (vm.searchObject.comment) {
                query = delimiter(query);
                query += '(comment: ' + vm.searchObject.comment + ' OR remark: ' + vm.searchObject.comment + ')';
            }

            console.log('processedSearchQuery:' + query);
            return query;
        }

        function delimiter(query) {
            return query + (!query.length ? '' : ' AND ');
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            initSearchObject();
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        vm.isAdmin = Principal.hasAnyAuthority(['ROLE_ADMIN']);

        function openSearchHelpDialog() {
            $uibModal.open({
                templateUrl: 'app/entities/registration/registration-search-help-dialog.html',
                controller: 'RegistrationSearchHelpDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg'
            });
        }

        function printTags(tags) {
            var tagLabel = "";
            if (tags && tags.length) {
                for (var i = 0; i < tags.length; i++) {
                    tagLabel += (tagLabel.length ? ", " : "") + tags[i].name;
                }
            }
            return tagLabel;
        }
    }
})();
